import React from "react";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import 'react-toastify/dist/ReactToastify.css';

function App() {

    return (
        <div className="bg">
            <Dashboard/>
        </div>
    );
}

export default App;
